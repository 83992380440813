.text-capitalise {
  text-transform: capitalize;
}

.py-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-10p {
  padding: 10px;
}

.managetipswrapper {
  padding: 41px 24px;

  .accordianWrapper {
    border-radius: 12px;
    border: 1px solid #f7f8f8;

    .accordian-wrapper-head {
      background-color: #e7e8e8;
      padding: 8px 12px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      span:last-child {
        min-width: 138px;
      }
    }

    .accordian-wrapper-body {
      font-size: 14px;
      line-height: 26px;

      .accordian-wrapper-row {
        border-bottom: 1px solid #f7f8f8;
      }

      .accordian-wrapper-row-bottom {
        border-bottom: 1px solid #f7f8f8;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }

  .iconsSec {
    column-gap: 12px;
    width: 160px;

    .eyeicon {
      width: 28px;
      height: 28px;
      background-color: #ece9f2;
      // background-color: #f3f2f1;
      text-align: center;
      line-height: 33px;
      cursor: pointer;
      border-radius: 4px;
    }

    .editicon {
      width: 28px;
      height: 28px;
      background-color: #ece9f2;
      // background-color: #f3f2f1;
      text-align: center;
      border-radius: 4px;
      line-height: 30px;
      cursor: pointer;

      &>span {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 20px;
  }

  .custom_accordian {
    margin-bottom: 24px !important;
    border-radius: 8px !important;

    &::before {
      display: none;
    }

    .MuiAccordionSummary-root {
      min-height: auto;
      border: none;

      &.Mui-expanded {
        border-bottom: 1px solid #ddd;

        .MuiAccordionSummary-content {
          margin: 15px 0;
        }
      }

      .MuiAccordionSummary-content {
        margin: 7px 0;
      }
    }
  }
}

.add-edit-tooltip {
  .d-flex {
    column-gap: 20px;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.6875rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.mb-24p {
  margin-bottom: 24px;
}

.View-tooltip-list {
  border: 1px solid #d0d5dd;
  margin-bottom: 24px;
  border-radius: 6px;
  padding: 16px 40px !important;
  min-height: 200px;

  li {
    list-style-type: disc;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.tip__box {
  border: 1px dashed #ffb300;
  background-color: #fff7e6;
  padding: 1.5rem 1rem;
  border-radius: 8px 8px;
  display: flex;
  width: calc(100% - 40px);
  min-height: 3.8rem;
  margin: 16px 20px 0px 20px;

  .tip__box_content {
    color: #3e3f40;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }
  .tip__box_icon {
    color: #383f43;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-top: -1rem;
  }
}

.tipContainer {
  box-shadow: none !important;
  border-radius: 8px !important;
  width: 248px !important;
}
.tipCardMain {
  display: flex;
  min-width: -webkit-fill-available !important;
  width: -webkit-fill-available !important;
  padding: 8px 12px !important;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #fff;
  li {
    margin-top: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  li::marker {
    height: 4px;
    width: 4px;
    font-size: 20px !important;
  }
}
.cardHeader {
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
  width: 100%;
  align-items: center;
  p {
    margin-left: 8px !important;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}

.tipsListWrapper {
  padding-left: 28px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: #3e3f40 !important;
  text-wrap: wrap;
  word-break: break-word;
}

.header__list-wrapper {
  // display: flex;
  height: calc(100vh - 400px);
  margin-top: 0.5rem;
  .list-item {
    padding: 12px 0px;
    border-bottom: 1px solid #b1b4b6;
    color: #3e3f40;
    font-size: 14px;
    font-weight: 500;
    display: flex;
  }
}

.dialog-btns {
  padding: 14px;
  .cancel-btn-reorder {
    width: calc(50% - 0.5rem);
  }
  .accept-btn-reorder {
    width: calc(50% - 0.5rem);
    margin-left: 1rem;
  }
}
.scrollable {
  overflow-y: auto;
  max-height: 300px;
}
.scrollable::-webkit-scrollbar-track {
  display: none;
}
.max-408 {
  max-width: 408px !important;
  .MuiDialogContent-root {
    padding: 20px 0px 20px 24px;
  }
}

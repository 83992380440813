.bulk-update-status {
  width: 81px;
  height: 24px;
  border-radius: 4px;
  padding: 0px 9px 0px 9px;
}

.IN_PROGRESS {
  color: #cc8f00;
  background-color: #fff7e6;
}
.COMPLETED {
  color: #005a30;
  background-color: #e6efea;
}
.SCHEDULED {
  color: #402080;
  background-color: #ece9f2;
}
.IN_QUEUE {
  color: #383f43;
  background-color: #e7e8e8;
}
.delete-asset-group {
  cursor: pointer;
  margin-left: 0.75rem;
  width: 24px;
  height: 24px;
  background-color: rgb(251, 235, 232);
  padding: 4px 4px 4px 4px;
  border-radius: 4px;
  border: unset;
}
.disable-btn {
  opacity: 0.5;
  cursor: default !important;
}
.downloadIcon {
  width: 1%;
  background-color: #e2deec;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-right: 4px;
  margin-left: 4px;
  cursor: pointer;
  &.disabledIcon {
    opacity: 0.7;
    pointer-events: none;
  }
}

.filter-l-search {
  margin: 10px 0;
  padding-left: 12px;
  .MuiTextField-root {
    height: 40px !important;
    width: 95%;
  }
}
.filter-l-title-clear {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px 6px 16px;
  border-bottom: 1px solid #f3f4f4;
  font-size: 12px;
  line-height: 24px;
  div {
    font-weight: 600;
    color: #3e3f40;
  }
  span {
    font-weight: 500;
    color: #402080;
    cursor: pointer;
  }
}
.clear-text {
  font-weight: 500;
  color: #402080;
  cursor: pointer;
  font-size: 12px;
  padding-left: 5px;
}
.filter-l-list-item {
  border: 1px solid #f3f4f4;
  padding: 7.5px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  .MuiCheckbox-root {
    width: 20px;
  }
}
.filter-l-list-buttons {
  padding: 12px 14px 12px 12px;
  border-top: 1px solid #f3f4f4;
  gap: 10px;
  button {
    width: 78px;
    height: 36px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
}
.filter-l-menu-item {
  max-height: 210px;
  min-width: 268px;
}
.filter-l-selected {
  border: #402080 2px solid;
  background-color: #ece9f2;
}
.filter-b-selected {
  border: #402080 1px solid !important;
  background-color: #ece9f2 !important;
  max-height: 26px !important;
}
.clear-all-btn {
  min-width: fit-content !important;
  padding: 6px 8px !important;
  line-height: 1 !important;
  span:first-child {
    border-bottom: 1px solid #402080;
    font-size: 14px;
  }
}
.asset-state-page .filter-l-container {
  height: 48px;
}
.filter-l-dropdown-menu .MuiPaper-root {
  margin-top: 10px !important;
}
.normal-border {
  border: 1px solid #b1b4b6;
  background-color: #ffff;
}
.primary_ul {
  border-bottom: 1px solid #402080;
}

.access__denied {
  margin-top: 10rem;
}

.message {
  text-align: center;
  width: 80%;
  margin: auto;
  margin-top: 5px;
  font-weight: 700;
  line-height: 28px;
}

.view_table {
  table {
    th {
      padding: 8px 12px !important;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 24px !important;
      white-space: nowrap !important;
    }

    td {
      padding: 12px !important;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
    }
  }
}
.max_w_10 {
  max-width: 10vw;
}

.exportProgress {
  color: #cc8f00;
  font-size: 12px;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }

  margin-right: 15px;
}

.exportBtn {
  // $primary_purple
  min-width: 68px !important;
  background-color: #fff !important;
  min-height: 36px !important;
  height: 40px !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px, 16px, 8px, 16px !important;
  line-height: 24px !important;
  font-family: 'Inter', sans-serif !important;
  color: #402080 !important;
  border: 1px solid #402080 !important;
}
.save_search_btn {
  font-size: 12px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  text-decoration: underline;
  color: #402080;
  text-underline-offset: 2px;
  cursor: pointer;
}

// Imported colors.scss file into index.scss to globally use it in project

@import './Assets/Styles/colors.scss';
@import './Assets/Styles/typography.scss';
@import './Assets/Styles/gridspacing.scss';

html,
body {
  background-color: #f5f4f4 !important;
  font-family: $primary-font !important;
  letter-spacing: $letter-spacing !important;
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;

  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(179, 179, 179);
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #d9d9d9;

  border-radius: 10px;
}

.interFont {
  font-family: Inter, sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

// heading
.heading-1 {
  font-size: $heading-1;
  font-weight: $font-bold;
  line-height: $heading-height-1;
}

.heading-2 {
  font-size: $heading-2;
  font-weight: $font-bold;
  line-height: $heading-height-2;
}

.heading-3 {
  font-size: $heading-3;
  font-weight: $font-bold;
  line-height: $heading-height-3;
}

.heading-4 {
  font-size: $heading-4;
  font-weight: $font-bold;
  line-height: $heading-height-4;
}

.heading-5 {
  font-size: $heading-5 !important;
  font-weight: $font-bold !important;
  line-height: $heading-height-5 !important;
}

.heading-6 {
  font-size: $heading-6;
  font-weight: $font-bold;
  line-height: $heading-height-6;
}

.heading-7 {
  font-size: $heading-6;
  font-weight: $font-weight-400;
  line-height: $heading-height-5;
}

// sub-heading
.sub-heading-1 {
  font-size: $heading-1;
  font-weight: $font-semi-bold;
  line-height: $heading-height-1;
}

.sub-heading-2 {
  font-size: $heading-2;
  font-weight: $font-semi-bold;
  line-height: $heading-height-2;
}

.sub-heading-3 {
  font-size: $heading-3;
  font-weight: $font-semi-bold;
  line-height: $heading-height-3;
}

.sub-heading-4 {
  font-size: $heading-4;
  font-weight: $font-semi-bold;
  line-height: $heading-height-4;
}

.sub-heading-5 {
  font-size: $heading-5;
  font-weight: $font-semi-bold;
  line-height: $heading-height-5;
}

.sub-heading-6 {
  font-size: $heading-6;
  font-weight: $font-semi-bold;
  line-height: $heading-height-6;
}

.sub-heading-7 {
  font-size: $heading-6;
  font-weight: $font-semi-bold;
  line-height: $heading-height-5;
}

// paragraph-bold
.paragraph-bold-1 {
  font-size: $paragraph-1;
  font-weight: $font-medium-bold;
  line-height: $paragraph-height-1;
}

.paragraph-bold-2 {
  font-size: $paragraph-2;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-2;
}

.paragraph-bold-3 {
  font-size: $paragraph-3;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-3;
}

.paragraph-bold-4 {
  font-size: $paragraph-4;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-4;
}

.paragraph-bold-5 {
  font-size: $paragraph-5;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-5;
}

.paragraph-bold-6 {
  font-size: $paragraph-6;
  font-weight: $font-bold;
  line-height: $paragraph-height-6;
}

.paragraph-bold-7 {
  font-weight: $font-weight-700;
  font-size: $heading-3;
}

.font-medium {
  font-weight: $font-semi-bold !important;
}
.paragraph-medium-bold-2 {
  font-size: 11px;
  font-weight: $font-semi-bold;
  line-height: $paragraph-height-3;
}

// paragraph-regular
.paragraph-regular-1 {
  font-size: $paragraph-1;
  font-weight: normal;
  line-height: $paragraph-height-1;
}

.paragraph-regular-2 {
  font-size: $paragraph-2;
  font-weight: normal;
  line-height: $paragraph-height-2;
}

.paragraph-regular-3 {
  font-size: $paragraph-3;
  font-weight: normal;
  line-height: $paragraph-height-3;
}

.paragraph-regular-4 {
  font-size: $paragraph-4;
  font-weight: normal;
  line-height: $paragraph-height-4;
}

.paragraph-regular-5 {
  font-size: $paragraph-5;
  font-weight: normal;
  line-height: $paragraph-height-5;
}

.paragraph-regular-6 {
  font-size: $paragraph-6;
  font-weight: normal;
  line-height: $paragraph-height-6;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.font-12 > p {
  font-size: 12px;
}

.word-break-warp {
  overflow: hidden;
  word-break: break-word;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.width-fill-available {
  max-width: -webkit-fill-available;
}

.gap-2 {
  gap: 2px;
}

.gap-12 {
  gap: 12px;
}

.gap-20 {
  gap: 20px;
}
.Z-index-1000 {
  z-index: 1000;
}
// Breadcrums Styling

.breadcrums__link {
  text-decoration: none;
  color: $secondary_lgrey-dark-active;
  margin-right: 5px;
  font-size: $paragraph-6;
  font-weight: 400;

  &.active {
    color: $primary_purple;
  }
}

//icon-styling

.primary__circle-icon {
  margin-right: 10px;
}

.circle__icon-wrapper {
  font-size: 20px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  border-radius: 50%;

  &.error {
    background-color: $accent_red-light;
    color: $accent_red;
  }

  &.success {
    background-color: $accent_green-light;
    color: $accent_green;
  }

  &.primary {
    background-color: $primary_purple-light;
    color: $primary_purple;
  }

  &.warning {
    background-color: $accent_yellow-light;
    color: $accent_yellow-normal;
  }

  .circle__icon-inner {
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 50%;

    &.error {
      background-color: $accent_red-light-hover;
    }

    &.warning {
      background-color: $accent_yellow-light-active;
    }

    &.success {
      background-color: $accent_green-light-hover;
    }

    &.primary {
      background-color: $primary_purple-light-hover;
    }

    svg {
      font-size: 30px;
    }
  }
}

// custom-chip buttons

.chip__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  height: 24px;
  border-radius: 4px;
  gap: 6px;
  max-width: fit-content;
  cursor: pointer;
  font-size: 12px !important;
  line-height: 24px;

  &.primary {
    background-color: $primary_purple-light;
    color: $primary_purple;
  }

  &.danger {
    background-color: $accent_red-light;
    color: $accent_red;
  }

  &.darkSuccess {
    background-color: #d9e6e0;
    color: $accent_green;
  }

  &.success {
    background-color: $accent_green-light;
    color: $accent_green;
  }

  &.info {
    background-color: $accent_yellow-light;
    color: $accent_yellow-normal-active;
  }

  &.secondary {
    background-color: $primary_black-light;
    color: $secondary_lgrey-darker;
    position: relative !important;
    border: 1px solid #dce3e8;
  }

  &.light {
    background-color: #fff;
    color: $primary_purple;
    position: relative !important;
  }

  &.darkPrimary {
    background-color: $primary_purple;
    color: #fff;
    position: relative !important;
  }

  .chip__button-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 18px;
    }
  }

  .chip__button-text {
    font-weight: normal;
    font-size: $paragraph-6;
  }
}

//modal index style
div.MuiDrawer-modal {
  z-index: 9999;
}

.whitespace__pre {
  white-space: pre;
}

.whitespace__preline {
  white-space: pre-line;
}

.whitespace__nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer !important;
}

.font-bold {
  font-weight: bold;
}

.text__capitalcase {
  text-transform: capitalize !important;
}

.pb-120 {
  padding-bottom: 120px;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative !important;
}

.login-screen-layout {
  margin: 0 !important;
  width: 100% !important;
  overflow: hidden !important;
}

.login-screen-content {
  position: absolute;
  bottom: 90px;
  color: #fff;
  width: 582px;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;

  h3 {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 150% */
    color: #fff;
    margin-bottom: 15px;
  }

  p {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 200% */
    color: #fff;
  }
}

.text-inter {
  font-family: Inter, sans-serif !important;

  &.active {
    svg * {
      fill: #402080;
    }
  }
}

.c-input.listing-search {
  input {
    min-width: 204px;
  }

  div {
    width: -webkit-fill-available;
  }
}

.c-input {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 48px !important;
  border-radius: 6px !important;
  background-color: #fff !important;

  div {
    height: 100% !important;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.c-input-br-6 {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 48px !important;
  border-radius: 6px !important;
  background-color: #fff !important;

  div {
    height: 100% !important;
    border-radius: 6px !important;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.c-btn {
  height: 48px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  padding: 12px, 16px, 12px, 16px !important;
  width: auto !important;
  min-width: 160px !important;
}

.save-btn {
  height: 50px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  padding: 12px, 16px, 12px, 16px !important;
  width: 203px !important;
  font-family: Inter, sans-serif !important;
}

.c-table {
  table {
    th {
      padding: 8px 12px !important;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 24px !important;
      white-space: nowrap !important;
    }

    td {
      padding: 12px !important;
      font-family: Inter, sans-serif !important;
      font-size: 12px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      white-space: nowrap !important;
    }
  }
}

.c-pagination {
  p {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    color: #3e3f40 !important;
    display: inline-block !important;
  }

  .MuiPaginationItem-page {
    font-weight: 500 !important;
  }

  .MuiPaginationItem-page.Mui-selected {
    border-radius: 0 !important;
  }

  nav {
    border-radius: 6px !important;
  }
}

.c-drop-down-pagination {
  div {
    padding: 10.5px 14px !important;
    font-family: Inter, sans-serif;
  }
}

.input-label-class.MuiFormLabel-root {
  top: -5px;
}

.input-label-class.MuiFormLabel-root.MuiInputLabel-shrink {
  top: 0;
}

.font__inter {
  font-family: $primary-font !important;
}

.listing__title {
  color: #111827 !important;
  font-family: $primary-font !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
}

.status__active {
  font-family: $primary-font !important;
  color: #005a30 !important;
  background-color: #e6efea !important;
  height: 24px !important;
  width: 54px !important;
  padding: 0 9px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  line-height: 24px !important;
}

.status__inactive {
  font-family: $primary-font !important;
  background-color: #ddddde !important;
  height: 24px !important;
  width: 69px !important;
  padding: 0 9px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  border-radius: 4px !important;
  line-height: 24px !important;
  color: #3e3f40 !important;
}

.full__height {
  height: 100vh;
}

.associated-users-custom-table > div {
  padding: 15px !important;
}

.associated-users-custom-table > div > div > div {
  margin: 0 !important;
}

.associated-users-custom-table {
  .c-pagination {
    margin-top: 30px !important;
  }
}

.custom-col {
  .c-table table th:nth-child(1) {
    width: 40% !important;
  }
}

.associated-users-count {
  display: inline-flex;
  align-items: center;
  padding-left: 16px;

  h6,
  p {
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  h6 {
    color: #6a6c6d;
    margin-right: 2rem;
  }

  p {
    color: #3e3f40;
  }
}

.font-12p {
  font-size: 12px;
}

.c-sort {
  .MuiPaper-rounded {
    box-shadow: 0px 20px 24px -4px rgba(56, 63, 67, 0.08) !important;
    border-radius: 12px;
    width: 174px;
    padding: 12px;

    .MuiTypography-root {
      font-size: 16px;
      color: rgba(25, 29, 35, 1);
      font-weight: 600;
    }

    .MuiFormGroup-root {
      gap: 8px;
      margin-top: 12px;

      li {
        padding: 0;

        label {
          margin: 0;
          padding: 6px 0;
          display: flex;
          justify-content: space-between;
          width: 100%;

          .ml-8p {
            margin-left: 8px;
          }

          span {
            padding: 0;
          }
        }
      }
    }
  }
}

.c-sort.common-table-filter-date {
  .MuiPaper-rounded {
    width: 265px;
  }
}

.content-open-layout {
  width: 100%;
  padding-left: 299px !important;
}

.content-close-layout {
  width: 100%;
  padding-left: 64px !important;
}

.c-modal {
  padding: 24px !important;

  h2 {
    display: inline-flex !important;
    margin-bottom: 20px;
  }

  div {
    .pt-1raph-bold-3 {
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color: #191d23;
      margin-bottom: 12px;
    }

    .paragraph-regular-5 {
      margin-bottom: 32px;
    }
  }

  .d-block {
    display: block;
  }

  .dialog-btns {
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: nowrap;

    .cancel-action-btn {
      flex-basis: 50%;

      &.w-100 {
        flex-basis: 100%;
      }
    }

    .accept-action-btn {
      flex-basis: 50%;
    }

    button {
      display: inline-flex;
      height: 44px;
      max-width: 170px !important;
      border-radius: 8px;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &:hover {
        background-color: none !important;
      }
    }

    .cancel-action-btn button {
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .accept-action-btn {
      &:hover {
        opacity: 0.9 !important;
      }
    }
  }

  h2,
  > div {
    padding: 0 !important;
  }
}

.c-active-inactive-dropdown > div {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}

.c-active-inactive-dropdown > div:hover > ul {
  background-color: #eee;
}

.c-active-inactive-dropdown > div > ul {
  padding: 0 !important;
}

.c-active-inactive-dropdown > div > ul > li {
  font-size: 14px !important;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;

  &.Mui-focusVisible {
    background-color: transparent;
  }
}

.submit__handler-btn-cont {
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px !important;
    min-width: 160px !important;
    border-radius: 6px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
}

.c-pagination > nav > ul > li > button {
  border-radius: 0 !important;
}

.c-btn {
  display: flex !important;
  padding: 12px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background-color: #402080 !important;
  min-width: 160px !important;
  height: 48px !important;
}

.tvShowModalInput > div > div > div {
  height: 50px;
}

.create__btn {
  width: auto !important;
  min-width: 160px !important;
  height: 48px !important;
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  font-family: $primary-font !important;
}

.scroll-x {
  overflow-x: auto;
}

.scroll-y {
  overflow-y: auto;
}

.text__decoration-underline {
  text-decoration: underline;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.opacity-half {
  opacity: 0.5;
}

.table-def {
  box-shadow: none !important;
  border: none;
  max-height: 80vh;

  .MuiTableCell-head {
    font-weight: $font-semi-bold;
    size: $paragraph-6;
    line-height: $paragraph-height-3;
    color: $primary_black;
    font-family: Inter, sans-serif;
  }

  .MuiTableCell-body {
    font-weight: $font-weight-400;
    size: $paragraph-6;
    line-height: $paragraph-height-3;
    color: $secondary_lgrey-darker;
    font-family: Inter, sans-serif;
  }
}

.disabled {
  opacity: 0.6 !important;
  cursor: default !important;
}

.MUIBox__parent {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  > .MuiBox-root {
    border: 1px solid rgba(25, 29, 35, 0.16);
  }

  &.locked {
    border: 1px solid #d4351c;
    border-radius: 8px;
  }

  .MUIBox__locked {
    height: 26px;
    border-radius: 4px;
    border: 1px solid var(--Accents_Red-Red, #d4351c);
    color: #d4351c;
    background: var(--Accents_Red-Shades-Light, #fbebe8);
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    position: absolute;
    z-index: 9;
    top: -10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 0 10px;
  }
}

.word-wrap {
  word-wrap: break-word;
}

.heading-6 .MuiFormControl-root label span span.ml-1 {
  display: flex;
  margin-left: 0 !important;
}

.drawer-btn-group {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-bottom: 16px;
  padding-right: 16px;
  margin-left: 0;
  width: 100%;
}

.parent-drawer-btn-group {
  padding-bottom: 40px;
}

.MuiGrid-container.cus_container {
  column-gap: normal !important;
}

.textEllipsesLine-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.textEllipsesLine-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.textEllipsesLine-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listing-filter {
  gap: 10px;

  .listing-filter-status {
    min-width: 150px;

    .MuiOutlinedInput-root {
      padding-right: 20px;
    }

    .c-drop-down-pagination {
      font-size: 14px !important;
      color: #6a6c6d !important;
    }
  }
}

.search-input {
  input {
    padding-left: 0 !important;
  }

  .MuiInputBase-root {
    width: var(--dynamic-width, 100%);
    background-color: white;
  }
}

.tab-c > div {
  height: 43px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.custom-input-h-48 {
  .MuiFormLabel-root {
    svg {
      height: 20px;
    }
  }

  .MuiOutlinedInput-input {
    height: 0.9375em;
  }
}

.max-height-260 {
  max-height: 260px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3.5px 4px 3.5px 5px !important;
  height: auto !important;
}

.content__padding {
  label {
    top: -2px !important;
  }

  .MuiGrid-container {
    // .MuiGrid-item:nth-child(even) {
    //   padding-left: 59px;
    // }
    .MuiAutocomplete-root {
      .MuiFormLabel-root {
        top: -2px !important;

        &.MuiInputLabel-shrink {
          top: 0;
        }
      }
    }
  }
}

.p-30-22 {
  padding: 30px 22px !important;
}

.rolebox__header-padding {
  .MuiButtonBase-root {
    padding: 0 15px;
  }
}

.list-icon {
  padding: 6px;
  width: 28px;
  height: 28px;
  background: #f3f2f1;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

span.dashLine {
  border: 1px dashed #ccc;
  display: block;
  width: 80px;
  position: relative;
}

span.dashLine-none {
  display: block;
  width: 80px;
  position: relative;
}

span.dashLine::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #888;
  right: 0;
  top: -4px;
}

span.dashLine::before {
  content: '';
  position: absolute;
  width: 1px;
  height: 64px;
  border: 1px dashed #ccc;
  top: -64px;
  left: -3px;
}

.video-media-info-pagination-select {
  .MuiSelect-select {
    display: flex;
    justify-content: space-between;
  }
}

.h-26px {
  height: 26px;
}

.text-capitalise {
  text-transform: capitalize;
}

.p-24p {
  padding: 24px;
}

.homepage-wrapper {
  padding: 24px;

  .card-wrapper {
    background-color: #ffff;
    padding: 1.2rem 1.2rem 2.6rem 1.2rem;
    border-radius: 20px;
  }

  .dashboard-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .card-padding {
    padding: 1rem 0.5rem 0px 1.5rem !important;
  }

  .column-gap-20 {
    column-gap: 20px;
  }

  .asset-status-slider {
    background-color: #fff;
    padding: 1.2rem 0px 1.2rem 1.2rem;
    border-radius: 20px;

    .slick-arrow.slick-prev {
      position: absolute;
      right: 4rem;
      left: auto;
      top: -32px;
      width: 36px;
      height: 36px;
      border: 1px solid #c3d3e2;
      border-radius: 4px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .slick-arrow.slick-disabled {
      opacity: 0.5;
    }

    .slick-prev:before,
    .slick-next:before {
      display: none;
    }

    .slick-track {
      margin-left: 0;
    }

    .slick-arrow.slick-next {
      position: absolute;
      right: 1rem;
      top: -32px;
      width: 36px;
      height: 36px;
      border: 1px solid #c3d3e2;
      border-radius: 4px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .box-item {
      padding: 20px;
      margin-right: 1.3rem;
      border-radius: 1rem;
      height: 220px;

      .status-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-bottom: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .status-val {
        font-weight: 700;
        color: #3e3f40;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 12px;
      }

      .status-text {
        font-weight: 600;
        color: #505152;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .dashboard-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;

    .lootie-text {
      color: #3e3f40;
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 10px;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }
  }

  .content-category-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 60%;

    .category-dropdown {
      .MuiAutocomplete-root {
        .MuiFormControl-root {
          .MuiInputBase-root {
            input::placeholder {
              color: rgba(0, 0, 0, 0.87) !important;
              opacity: 1;
              font-weight: 500;
              font-size: 14px;
            }

            height: auto;
            padding: 0px 0px 0px 9px;

            .MuiChip-label {
              padding: 1px 10px 1px 0px;
            }

            .MuiButtonBase-root {
              &.MuiChip-root {
                padding: 1px 0px 1px 0px;
                font-size: 14px;
              }
            }
          }
        }
      }

      fieldset {
        legend {
          width: 0;
        }
      }

      gap: 17px;
      position: absolute;
      right: 0;

      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 0;
        margin-top: 5px;
        white-space: nowrap;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        min-width: 200px;
        max-width: 250px;
        position: relative;
        z-index: 99;

        .MuiSelect-select {
          min-height: auto;
          padding: 6px 12px 7px;
          width: 76px;
        }
      }

      .MuiInputBase-input {
        height: 0;
      }
    }

    span {
      font-size: 14px;
      font-weight: 500;
      font-family: inter, sans-serif;
      display: flex;
      align-items: center;
    }

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .category-list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 22px;

      li {
        list-style: none;
        display: flex;
        column-gap: 15px;

        &:nth-child(1),
        &:nth-child(4) {
          width: 40%;
        }

        &:nth-child(2),
        &:nth-child(5) {
          width: 40%;
        }

        &:nth-child(6),
        &:nth-child(3) {
          width: 20%;
        }

        span {
          width: 22px;
          height: 22px;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 500;
          color: #6a6c6d;
        }
      }
    }
  }

  .recent-activity-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(200, 200, 200);
    border-radius: 5px;
    display: none;
  }

  .recent-activity-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 40%;
    overflow: auto;
    max-height: 615px;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .recent-activity-list {
      overflow: visible;
      padding-right: 10px;
      border-left: 2px dashed #ddd;
      margin-top: 10px;
      margin-left: 10px;

      .activity-row {
        border-bottom: 2px dashed #ddd;
        padding: 10px 0;
        position: relative;
        cursor: pointer;

        &:first-child {
          padding-top: 0px;

          .icon-left {
            position: absolute;
            left: -8px;
            top: 1px;
          }
        }

        .icon-left {
          position: absolute;
          left: -8px;
          top: 11px;
        }

        .publised-id {
          font-size: 14px;
          line-height: 24px;
          color: #3e3f40;
          font-weight: 600;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
          }
        }

        .publised-text {
          font-size: 12px;
          line-height: 24px;
          font-weight: 500;
          color: #6a6c6d;
        }

        .publised-author {
          font-size: 12px;
          line-height: 24px;
          font-weight: 500;
          color: #3e3f40;
          display: flex;
          justify-content: space-between;

          span {
            font-weight: 400;
          }
        }
      }
    }
  }

  .asset-type-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 70%;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .category-dropdown {
      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 12px;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        width: 76px;

        .MuiSelect-select {
          min-height: auto;
          padding: 6px 12px 7px;
          width: 76px;
        }
      }
    }
  }

  .published-asset-wrapper {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    flex-basis: 30%;

    h3 {
      font-size: 18px;
      line-height: 28px;
      color: #111827;
      font-weight: 600;
      margin-bottom: 13px;
      display: flex;
      column-gap: 8px;
    }

    .publised-val {
      font-size: 24px;
      line-height: 34px;
      font-weight: 700;
      color: #3e3f40;
    }

    .category-dropdown {
      > label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #151d48;
        margin-right: 12px;
      }

      .MuiInputBase-root {
        border-radius: 8px;
        width: 121px;

        .MuiSelect-select {
          min-height: auto;
          padding: 6px 12px 7px;
          width: 121px;
        }
      }
    }
  }
}

.in-progress {
  animation: rotate 1s linear infinite;
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.mt-3 {
  margin-top: 3rem !important;
}
.ml-1 {
  margin-left: 1rem;
}
.mt-neg5 {
  margin-right: -5px;
}

.pl-2 {
  padding-left: 2rem !important;
}

.global-search-input {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
  height: 40px !important;
  border-radius: 6px 0px 0px 6px;
  background-color: #fff !important;

  div {
    height: 100% !important;
    border-radius: 6px 0px 0px 6px;
  }

  ::placeholder {
    font-family: Inter, sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #545454 !important;
  }
}

.advanced-search {
  display: flex;

  .MuiFormControl-root {
    .MuiFormLabel-root {
      top: -7px;

      &.MuiInputLabel-shrink {
        top: 0;
        z-index: 9;
      }
    }

    .MuiInputBase-root {
      border-radius: 6px 0px 0px 6px;
      width: 302px;

      .MuiInputBase-input {
        height: auto;
        padding: 8px 16px 9px 0;
        background: #fff;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }

  .search-advanced-btn {
    height: 40px;
    background-color: #ece9f2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-left: 3px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 40px;
    cursor: pointer;
  }
}

.metaattributes-fields {
  width: calc(100% - 50px);
}

.metaattribute-relative {
  margin-top: 33px;
  padding-left: 8px;
}

.delete-icon {
  position: absolute;
  right: 0;
  top: 35px;

  @media (min-width: 1200px) and (max-width: 1300px) {
    body {
      zoom: 0.9;
    }
  }

  @media (min-width: 1301px) and (max-width: 1400px) {
    body {
      zoom: 0.95;
    }
  }

  .JSON__dialog-width {
    width: 500px;
  }

  .table-link:link {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:visited {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:hover {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }

  .table-link:active {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
  }
}

.border__box_width_hr {
  border: 1px solid rgba(25, 29, 35, 0.16);
}

.filter__width {
  width: 200px;
}

.JSON__dialog-width {
  width: 500px;
}

.table-link:link {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:visited {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:hover {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.table-link:active {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
  /* no underline */
}

.custom-muiAutocomplete {
  .MuiFormLabel-root {
    &.MuiInputLabel-shrink {
      top: 0;
    }
  }

  .MuiAutocomplete-root {
    .MuiInputBase-root {
      gap: 3px;
      padding: 8px 50px 8px 8px;

      .MuiChip-root {
        margin: 0;
      }
    }
  }
}

.media-wrapper {
  .rolebox__header-padding {
    border-bottom: 1px solid rgba(177, 180, 182, 0.5);
  }

  .MuiAccordionDetails-root {
    padding: 12px 9px;

    .MuiGrid-container {
      > .MuiGrid-item.MuiGrid-grid-xs-12 {
        > .MuiGrid-container {
          > .MuiGrid-grid-xs-12.MuiGrid-item:first-child {
            border-bottom: 1px solid #e7e8e8;
          }
        }
      }
    }
  }

  div[class*='AssetEditor'].MuiGrid-item {
    &:first-child {
      padding-top: 42px !important;
    }

    padding-top: 16px !important;
  }

  .MuiAccordion-rounded {
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-radius: 8px !important;

    .MuiAccordionSummary-root {
      border-bottom: none;
      min-height: 41px;

      &.Mui-expanded {
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        min-height: 41px;
      }

      .MuiAccordionSummary-content {
        margin: 8px 0;
      }
    }
  }
}

.h-28 {
  height: 28px;
}

.custom-chip-wrap {
  .MuiStack-root {
    max-width: 210px;

    .MuiChip-root {
      height: 24px;
    }
  }
}

.line-wrap {
  width: 40ch;
  text-wrap: wrap;
  word-break: break-all;
}

.vjs-workinghover {
  width: 100% !important;

  video {
    width: 100% !important;
    aspect-ratio: 16 / 9 !important;
  }
}

.video-js {
  padding-top: calc(100% - 300px) !important;
}

.vjs-skip-button {
  position: absolute;
  bottom: 48px;
  right: 12px;
  padding: 4px 10px;
  cursor: pointer;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #402080 !important;
  font-size: 0.8125rem !important;
  font-weight: 500;
  line-height: 1.75 !important;
  letter-spacing: 0.02857em;
}

.vjs-skip-button:hover {
  background-color: rgb(44, 22, 89) !important;
}

.graph-tooltip {
  position: relative;

  &:before {
    content: '';
    width: 15px;
    height: 20px;
    border-right: solid 10px #3e3f40;
    border-bottom: solid 10px transparent;
    border-top: solid 10px transparent;
    left: -15px;
    top: 35%;
    position: absolute;
  }
}

.add-column-container {
  height: 41px;
  padding: 10px 12px 10px 12px;
  line-height: normal;
  border-radius: 6px;
  text-align: flex-end;

  .arrow-icon {
    margin-top: 6px;
    color: #6a6c6d;
  }
}

.font-14 {
  font-size: 14px;
}

.refresh-icon {
  width: 48px;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

.targeting-wrapper {
  margin-bottom: 1px;
  width: 50%;

  div {
    div {
      div {
        input {
          min-width: 0px !important;
        }

        div {
          max-width: 165px !important;
        }
      }
    }
  }
}

.segment-id-wrapper {
  width: 50%;

  > .MuiAutocomplete-root {
    > .MuiFormControl-root {
      > .MuiInputBase-root {
        min-height: 48px;
      }
    }
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.MuiFormHelperText-root {
  white-space: pre-line;

  &.Mui-error {
    margin-left: 0 !important;
  }
}

.d-none {
  display: none;
}

.dialog__padding-horizontal {
  padding-left: 24px;
  padding-right: 24px;
}

.dialog__padding-vertical {
  padding-top: 24px;
  padding-bottom: 24px;
}

.dialog__overflow {
  max-height: 35vh;
  overflow: auto;
}

.duration-dropdown {
  top: 56px !important;

  .duration-layout-root {
    display: flex;
    flex-direction: row;

    .duration-wrapper {
      display: flex;
      flex-direction: column;

      .duration-time {
        overflow: hidden;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 1px;

        ul {
          overflow-y: scroll;
          scroll-behavior: auto;
          max-height: 232px;

          li {
            cursor: pointer;
            margin: 5px;
            padding: 5px;
            text-align: center;

            &:last-child {
              margin-bottom: 120px;
            }
          }

          li:hover {
            background-color: rgba(64, 32, 128, 0.04);
          }

          li.selected {
            background-color: rgb(64, 32, 128);
            color: rgb(255, 255, 255);
          }
        }
      }
    }
  }

  .duration-action {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
  }
}

.clear-all {
  font-weight: 500;
  color: #402080;
  cursor: pointer;
  font-size: 12px;
}

.clear-all-disabled {
  font-weight: 500;
  color: #402080;
  font-size: 12px;
  opacity: 0.5;
}

.sub-heading-8 {
  font-size: $heading-6;
  font-weight: $font-weight-400;
  line-height: $heading-height-6;
}

.jsonInputError {
  .MuiFormHelperText-root {
    font-size: 14px !important;
    font-family: Inter;
  }
}

.btn-fetching-data {
  border: none;
  background-color: transparent;
  gap: 4px;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  .fetch-refresh-icon {
    font-size: 17px;
    margin-top: -2px;
    font-weight: 400;
  }
}

.text-underline {
  text-decoration: underline;
}

.mtn-14 {
  margin-top: -14px !important;
}

.mb-14 {
  margin-bottom: 14px;
}

.mbn-7 {
  margin-bottom: -7px;
}

.content-page-scroll {
  max-height: calc(100vh + 170px - 60px);
  overflow: hidden;
}

.gap-24 {
  gap: 24px;
}
.gap-18 {
  gap: 18px;
}
.gap-6 {
  gap: 6px;
}

.boolean-dropdown-wrapper {
  .MuiFormControl-root {
    .MuiSelect-select {
      display: flex;
    }
  }
}

.paragraph-regular-55 {
  margin-bottom: 24px;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input:-webkit-autofill,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
}

.MuiFormControl-root {
  .MuiFormLabel-root {
    line-height: 1.2rem !important;
    padding: 0 0 0 0 !important;

    & > div > div {
      margin: 0 0 0 0 !important;
    }
  }

  .MuiInputBase-root {
    padding: 14px 16px 14px 16px !important;

    input {
      &::placeholder {
        padding: 0 0 0 0 !important;
      }
    }

    .MuiInputBase-input {
      padding: 0 0 0 0 !important;
    }

    &.MuiInputBase-multiline {
      padding: 0;
    }
  }

  .MuiCheckbox-root {
    &.Mui-disabled.Mui-checked {
      color: rgb(64 32 128 / 40%);
    }
  }
}

.mainWrapper {
  .MuiDialogContent-root {
    padding: 20px 0 !important;
  }
}

.left-list-wrapper,
.right-list-wrapper {
  .MuiBox-root {
    .MuiFormControl-root {
      .MuiFormControlLabel-root {
        .MuiCheckbox-root {
          color: #b1b4b6 !important;

          .MuiSvgIcon-root {
            height: 20px !important;
            width: 20px !important;
          }
        }
      }
    }
  }
}

.reorder-fixed-action-btns {
  .MuiDialogActions-root {
    padding: 0 0.5rem !important;
  }
}

.retrigger-disabled {
  .chip__button {
    cursor: auto;
  }
}

.partner-form-icon-add {
  padding: 0 0 0 0 !important;
  margin: 0 0 0 0 !important;
}

.partner-asset-type-config-wrapper {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 6px;

  .config-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 1rem;
  }

  .config-form {
    padding: 1rem;
  }
}

.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.s3-dialog-btns {
  padding: 14px;

  .cancel-action-btn {
    width: calc(50% - 0.5rem);
  }

  .accept-action-btn {
    width: calc(50% - 0.5rem);
    margin-left: 1rem;
  }
  button {
    padding: 10px 18px;
    display: inline-flex;
    height: 44px;
    max-width: 170px !important;
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &:hover {
      background-color: none !important;
    }
  }
}

.image-apply-filter-btn {
  margin-right: 1.5rem !important;
  text-transform: capitalize !important;
}

.image-filters-section-title {
  padding: 1rem 1.5rem 1rem 1.5rem !important;
  border-bottom: 1px solid #eee;
  &.input {
    label {
      top: -3px !important;
    }
  }
}

.image-filters-drawer-footer {
  padding: 1rem 0 !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 428px !important;
  z-index: 1 !important;
  background-color: #fff !important;
}

.image-filters-btn-wrapper {
  min-width: 180.5px !important;
  height: 52px !important;
  border-radius: 8px !important;
}

.image-filters-content-wrapper {
  width: 100%;
  font-size: unset;
  padding: 1.5rem 1.5rem 0 1.5rem !important;

  // .required-field {
  //   width: 100%;
  // }

  // .contentLabel {
  //   color: #666;
  //   width: 30%;
  //   margin-bottom: 1rem;
  // }

  // .contentValue {
  //   display: flex;
  //   flex-wrap: wrap;
  //   margin-left: 1rem;
  //   width: 70%;
  //   overflow: hidden;
  //   word-break: break-word;
  // }
}

.image-filters-content-wrapper > div {
  width: 100% !important;
}

.image-filters-dropdown-section {
  padding: 0rem 0rem 0.5rem 0rem;
}

.unset-btn {
  all: unset;
}
div.ml-1 > a:first-child {
  text-decoration: none !important;
}

.table-row-clickable:hover {
  .list-sticky-header,
  .list-sticky-header {
    .chip__button.primary {
      background-color: #ffff;
    }
  }
}

.home-category-dropdown .MuiFormControl-root .MuiInputBase-root {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin-top: -2px;
  .MuiAutocomplete-endAdornment {
    top: 4px !important;
  }
  .MuiAutocomplete-tag {
    height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.add-extra-custom-input {
  background-color: #e7e8e852 !important;
}

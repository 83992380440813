@import '../../../Assets/Styles/colors.scss';
@import '../../../Assets/Styles/typography.scss';

.wrapper {
  &.bulk__image {
    height: calc(100vh - 150px);
  }

  position: relative;

  .asset__editor {
    display: flex;
    // margin-top: 12px;

    .create-asset-video {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .asset__editor-menu {
      display: flex;
      height: fit-content;
      min-width: 265px;
      flex-direction: column;
      align-items: flex-start;

      .MuiBox-root {
        background: transparent;
      }

      .menu {
        .menu-heading {
          text-align: left;
          display: flex;
          padding: 20px 12px;
          align-items: center;
          align-self: stretch;
          color: $secondary_dark-grey;
          font-family: Inter, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          border-bottom: 1px solid $secondary_lgrey-light-active;
          background: #fff;

          span {
            margin-left: 5px;
          }
        }

        .menu-heading:not(:first-child) {
          margin-top: 1rem;
        }
        .associated-tab {
          display: flex;
          padding: 20px 12px 20px 16px;
          align-items: center;
          border-bottom: 1px solid $secondary_lgrey-light-active;
          background: #fff;
          color: $secondary_lgrey-darker;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          cursor: pointer;
        }
        .menu-content {
          display: flex;
          padding: 20px 12px 20px 16px;
          align-items: center;
          border-bottom: 1px solid $secondary_lgrey-light-active;
          background: #fff;
          color: $secondary_lgrey-darker;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          cursor: pointer;
          height: 50px;

          &.active {
            border-radius: 2px;
            border-left: 4px solid $primary_purple;
            background: $primary_purple-light;
            color: $primary_purple-normal;
            font-weight: 600;
          }
        }
      }
    }

    .asset__editor-content,
    .asset__editor-bulk-image-content {
      // width: calc(100% - 575px); // commented out to fix the width issue
      width: calc(100vw - 630px);

      &.fullWidth_content {
        width: calc(100% - 266px);
      }

      .header__padding {
        padding: 1.0625rem 1.25rem;
      }

      .content__padding {
        padding: 2.5rem 1.2rem;

        .content__padding {
          padding: 1.25rem 0.75rem;
        }
      }
    }

    .asset__editor-extras {
      width: 262px;
    }
  }

  .asset__editor-bulk-image-extras {
    width: 262px;
  }

  padding: 16px;

  .asset__editor-extras {
    width: 262px;
    max-width: 262px;
    height: fit-content;
  }
  .create-asset-meta-form {
    padding: 0 22px 30px;
    margin-top: 0;
  }
  .p-30-22-0 {
    padding: 30px 22px 0;
  }
  .p-22-22-0 {
    padding: 0 22px 0 22px;
  }
  .create-asset-ql-section {
    .mui-tip {
      width: 100% !important;
    }
    .search-attribute-wrapper,
    .associated-container {
      margin-bottom: 0;
    }
  }
  .hide-no-child:not(:has(.MuiStack-root)) {
    display: none;
  }
}
.root:has(.asset-editor-wrapper-submit) {
  .asset-editor-wrapper {
    padding-bottom: 100px;
  }
}

.wrapper.content-page-scroll {
  .menu-meta-categories {
    max-height: calc(100vh - 310px);
    overflow-y: auto;
  }
  .asset__editor-extras {
    max-height: calc(100vh - 87px);
    overflow-y: auto;
  }
  .asset__editor:has(.create-asset-meta-form) {
    .menu-meta-categories {
      max-height: calc(100vh - 399px);
    }
    .asset__editor-extras {
      max-height: calc(100vh - 174px);
    }
  }
  .create-asset-image {
    max-height: calc(100vh - 145px);
    overflow-y: auto;
  }
  .create-asset-video,
  .create-asset-checklist {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
  .create-asset-meta-view {
    max-height: calc(100vh - 163px);
    overflow-y: auto;
  }
  .asset__editor:has(.MuiTabs-root) {
    .create-asset-meta-view {
      max-height: calc(100vh - 214px);
    }
    .create-asset-meta-form {
      max-height: calc(100vh - 314px);
    }
  }
  .create-asset-meta-form {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
  }
}

.wrapper:has(.sticky-header) {
  .asset__editor {
    .create-asset-meta-view {
      padding-top: 59px !important;
    }
    .create-asset-video {
      padding-top: 75px !important;
    }
    .create-asset-meta-form,
    .create-asset-image,
    .create-asset-checklist {
      padding-top: 61px !important;
    }
    .create-asset-video .create-asset-meta-view {
      padding-top: 0px !important;
    }
  }
  .asset__editor:has(.MuiTabs-root) {
    .create-asset-meta-view {
      padding-top: 125px !important;
    }
    .create-asset-video {
      padding-top: 141px !important;
    }
    .create-asset-meta-form,
    .create-asset-image,
    .create-asset-checklist {
      padding-top: 135px !important;
    }
  }
}

.tag__input {
  width: 100%;

  .MuiTextField-root {
    .MuiInputBase-formControl.MuiInputBase-multiline {
      display: flex;
      flex-wrap: wrap;
    }

    .MuiOutlinedInput-root {
      padding-left: 0;

      .MuiOutlinedInput-input {
        font-size: 14px;
        color: rgba(0, 0, 0, 1);

        &:placeholder {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  .MuiInputBase-root {
    padding: 0;
  }
}

.parent__Id-success {
  display: flex;
  align-items: center;
  min-height: 52px;
  padding: 0px 12px;
  align-self: stretch;
  background: var(--Accents_Green-Shades-Light, #f7f8f8);
  background-color: #f7f8f8;
  margin-top: 1rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */

  justify-content: space-between;
  gap: 5px;

  :first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  :nth-child(2) {
    display: block;
  }
}

.parent__Id-success-title {
  display: flex;
  min-height: 52px;
  padding: 0px 12px;
  align-items: center;
  align-self: stretch;
  background: var(--Accents_Green-Shades-Light, #f7f8f8);
  background-color: #f7f8f8;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 185.714% */

  justify-content: space-between;
  gap: 5px;

  :first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  :nth-child(2) {
    display: block;
  }
}

.parent__Id-error {
  display: flex;
  min-height: 52px;
  padding: 0px 12px;
  align-items: center;
  align-self: stretch;
  margin-top: 1rem;

  background: var(--Accents_Green-Shades-Light, #f7f8f8);

  color: var(--Accents_Red-Red, #d4351c);
  /* Paragraph_6_medium */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 200% */
}

.box__header-padding {
  padding: 1.2rem;
}

.hr {
  height: 2px;
  width: 100%;
  background-color: $border-bottom-grey;
}

.submit__handler {
  width: 100%;
  background-color: #ffffff;
  padding: 1rem 2rem;
  position: fixed;
  min-height: 40px;
  bottom: 0;
  left: 0;

  &.asset__editor-btn {
    height: auto;
  }

  .submit__handler-btn-cont-asset {
    width: calc(100vw - 325px);
    text-align: right;
  }
}

.title__style {
  color: #191d23;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 185.714% */
}

.icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: auto !important;
  margin-left: -24px !important;
  margin-right: -10px !important;
}

.asset__editor-header {
  display: flex;

  .asset__editor-info {
    width: 100%;
  }

  .asset__editor-info.is-child {
    width: calc(100vw - 340px);
  }

  .asset__editor-child {
    width: 285px;

    button {
      margin-left: 24px;
      margin-top: 26px;
      width: -webkit-fill-available;
      padding: 9px 22px;
    }
  }
}
.add-contnt-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  color: $secondary_lgrey-darker;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  position: relative;

  .audio_video-list-head {
    width: 69%;
    word-wrap: break-word;
  }

  .audio_video-list-content {
    width: 9%;
    margin-left: 20px;
    position: relative;
  }

  .audio_video-last-update {
    width: 22%;
    margin-left: 20px;
    position: relative;
  }
  .bold {
    font-family: Inter, sans-serif;
    font-weight: 600;
  }
}
.audio_video_list-container {
  height: 15rem;
  margin-bottom: 0rem;
  overflow-y: auto;

  .audio_video-list {
    padding: 1rem;
    display: flex;
    align-items: center;
    color: $secondary_lgrey-darker;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #b1b4b6;
    cursor: pointer;
    width: 100%;

    &.active {
      background-color: #f3f2f1;
    }

    .audio_video-list-head {
      width: 69%;
      word-wrap: break-word;
      padding-right: 2rem;
    }

    .audio_video-list-content {
      width: 9%;
      // text-align: center;
    }

    .audio_video-last-update {
      width: 22%;
      text-align: center;
    }

    .bold {
      font-family: Inter, sans-serif;
      font-weight: 600;
    }
  }
}

.s3-file-container {
  max-height: calc(80vh - 348px);
  height: auto;
  .s3-file-list {
    padding-block: 0.7rem !important;
  }
}

.add__audio_video-button {
  display: flex;
  padding: 2px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: #ece9f2;
  outline: none;
  border: 0;
  color: var(--Primary_Purple-Primary_Purple, #402080);

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.accordion__padding {
  padding: 8px 16px 16px;
}

.other-versions {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  background: var(--White, #fff);

  .version-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .detail-header {
    color: #505152;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }

  .detail-item {
    color: #505152;
    font-size: 9px;
    font-weight: 500;
    line-height: 16px;
  }
}

.content-body {
  max-height: 40rem;
  margin-bottom: 6rem;
}

.fixed-action-btns {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;

  &.media-info-edit {
    padding: 20px 16px;
    gap: 20px;
    background-color: #fff;
    width: 100%;
    position: static;
  }
}

.dialog-loader {
  position: absolute;
  height: calc(100vh - 60px);
  width: calc(100vw);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &.dialog-page {
    position: static;
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.heading-indent {
  margin-left: 24px;
}

.media-info-content {
  height: 25rem;
}

.media-info-form-content {
  padding: 20px 24px 20px 24px;
  overflow-y: auto;
  max-height: 55vh;
  overflow-x: hidden;
}

.asset-header-badge {
  position: absolute;
  top: -14px;
  right: 80px;
  z-index: 9;
}

.avs-status-wrapper {
  position: absolute;
  top: -14px;
  right: 80px;
  z-index: 9;

  .avs-status-box {
    padding-left: 9px;
    padding-right: 9px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline-flex;

    .avs-status-label {
      font-size: 12px;
      font-family: Inter, sans-serif;
      font-weight: 400;
      line-height: 24px;
      word-wrap: break-word;
    }

    .avs-success-label-color {
      color: #4c6a0b;
    }

    .avs-failed-label-color {
      color: #d4351c;
    }
  }

  .avs-success-color {
    color: #4c6a0b;
    background-color: #f4f9ea;
    border: 1px solid #4c6a0b;
  }

  .avs-failed-color {
    color: #d4351c;
    background-color: #ffdbd5;
    border: 1px solid #d4351c;
  }
}

.url-wrapper {
  width: 99.7%;
}
.autocomple-class {
  .MuiButtonBase-root {
    height: 24px;
  }
  .MuiAutocomplete-tag {
    margin: 2px;
  }
}
.fs-25 {
  font-size: 25px;
}
.p-13-12 {
  padding: 13px 12px;
}

.Checkbox-label {
  margin-left: 5px;
  padding-bottom: 25px;
}

.level1 {
  font-family: Inter, sans-serif;
  font-size: 13px;
  text-align: left;
  padding-top: 3px;
  line-height: 24px;
}

.level2 {
  font-family: Inter, sans-serif;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 3px;
  line-height: 26px;
}

.bold {
  font-weight: 700;
}

.border-bottom {
  border-bottom: 2px solid $border-bottom-grey;
}

@import '../../Assets/Styles/colors.scss';
@import '../../Assets/Styles/typography.scss';

.associated-container {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.role__editor {
  height: 100%;
  overflow-y: auto;
  .MuiGrid-container:has(.view__role-list) {
    border-bottom: 1px solid $border-bottom-grey;
  }
  .role__editor-wrapper {
    display: flex;
    width: 100%;
    .role__editor-content {
      width: calc(100% - 280px);
      &.associated__users {
        width: 100%;
      }
      .hr {
        height: 2px;
        width: 100%;
        background-color: $border-bottom-grey;
      }

      .view__role-list {
        padding: 0.875rem 1rem;
      }

      .rolebox__content-padding {
        padding: 30px 20px; //2.5rem 1.2rem; (as per figma design)
        .MuiGrid-container {
          margin: 0;
          width: 100%;
          row-gap: 24px;
          column-gap: 65px;
          .MuiGrid-item {
            padding: 0;
            &.MuiGrid-grid-md-6 {
              max-width: calc(50% - 33px);
            }
          }
        }
      }

      .rolebox__header-padding {
        padding: 13px 20px;
        .chip__button {
          height: 28px !important;
        }
      }

      .rolebox__icon-margin {
        margin-right: 10px;
      }

      .checkbox__label {
        margin-left: 5px;
      }

      .permissions__box_content-padding {
        padding: 0.2rem 1rem;
        &.content {
          padding: 0.5rem 1rem;
        }
        &.border {
          border-left: 1px solid $border_grey;
          border-right: 1px solid $border_grey;
        }
        .MuiFormControlLabel-root {
          span.ml-1:empty {
            display: none;
          }
        }
      }

      .tip__box {
        border: 1px dashed $accent_yellow;
        background-color: $accent_yellow-light;
        padding: 1.5rem 1rem;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        .rolebox__header-padding {
          padding: 1.2rem;
        }
        .tip__box-content {
          color: $secondary_lgrey-darker;
          font-size: $paragraph-6;
          font-weight: normal;
          text-align: left;
          display: flex;
          align-items: center;
          margin-left: 1.5rem;
        }
        .tip__box-icon {
          color: $secondary_dark-grey;
          font-size: $heading-6;
          font-weight: $font-semi-bold;
          text-align: left;
        }
      }
    }
  }
}

.submit__handler {
  background-color: $white;
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 15px;
  z-index: 1;
  .submit__handler-btn-cont {
    width: calc(100vw - 350px);
    text-align: right;
  }
}

.contentState {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding: 4px 9px;
  border-radius: 4px;
  // padding: 0px, 9px, 0px, 9px;
  color: #0073a1;
  background-color: #e6f7fd;
}
.DRAFT,
.DRAFT_IN_PROGRESS {
  color: #9f760d;
  background-color: #ffefd1;
}
.DRAFT_COMPLETE {
  color: #5c4200;
  background-color: #f4e7c8;
}
.QC_PASSED,
.QC_FAILED,
.QC_BY_PASS,
.QC_IN_PROGRESS,
.DRAFT_UNDER_QC {
  color: #402080;
  background-color: #ece9f2;
}
.READY_FOR_PUBLISHING {
  color: #160b2d;
  background-color: #d3cce4;
}
.CREATED,
.SCHEDULE_PUBLISH {
  color: #0073a1;
  background-color: #e6f7fd;
}
.PUBLISHED {
  color: #005a30;
  background-color: #e6efea;
}
.RIGHTS_EXPIRED {
  color: #d4351c;
  background-color: #f9e1dd;
}
.DEPUBLISHED {
  background-color: #f2c0b9;
  color: #4a130a;
}
.FAILED,
.PUBLISH_FAIL,
.ARCHIEVED,
.DE_PUBLISH,
.DEPUBLISH {
  color: #d4351c;
  background-color: #f9e1dd;
}

.tableContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  &.transparent {
    background-color: transparent;
  }
  table {
    th {
      padding: 8px 12px;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      white-space: nowrap;
      div {
        display: flex;
        gap: 0.2rem;
        align-items: center;
        align-content: space-between;
        font-size: 12px;
        font-weight: bold;
      }
    }
    td {
      padding: 4px 12px;
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
      div {
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
      }
    }
  }
}
.tableHeader {
  display: flex;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: block !important;
  }
}
.tableRowClickable:hover {
  cursor: pointer;
  background-color: #ece9f2;
  .list_sticky_header_R,
  .list_sticky_header_H {
    background-color: #ece9f2;
  }
}
.list_sticky_header_R {
  position: sticky;
  right: 0;
  z-index: 99;
  background-color: #ffff;
}
.list_sticky_header_L {
  position: sticky;
  left: 0;
  z-index: 99;
  background-color: #ffff;
}

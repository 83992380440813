.display-value-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.clamped-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2; /* Default max lines */
  line-clamp: 2;
}

.expanded {
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.view-more-less {
  align-self: flex-end;
}

.flex-row-between {
  .text-value:after {
    content: '';
    display: inline-block;
    height: 2px;
    width: 110px; /* the value of padding */
  }
  .clamped-text.text-value:after {
    width: 0px; /* the value of padding */
  }
  .clamped-text.clamped.text-value:after,
  .clamped-text.expanded.text-value:after {
    width: 80px; /* the value of padding */
  }
}
.min-w-fit-content {
  min-width: fit-content;
}
.fixed-view-more {
  position: absolute;
  right: 0;
  bottom: 0;
}
.not-fixed-view-more {
  position: relative;
}

.box__padding_bulk {
  padding: 16px 12px;
}

.tip__box {
  border: 1px dashed #ffb300;
  background-color: #fff7e6;
  padding: 1.5rem 1rem;
  border-radius: 8px 8px;
  display: flex;
  width: calc(100% - 40px);
  min-height: 3.8rem;
  margin: 16px 12px;

  .tip__box_content {
    color: #3e3f40;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }
  .tip__box_icon {
    color: #383f43;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.bulk__image-card {
  overflow: hidden;
  border-radius: 4px;
  .bulk__image-card-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
      object-position: revert;
      height: 100%;
      width: auto;
    }
    .editIcon {
      background-color: #191d23;
      opacity: 0.9;
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;
    }

    .overlay {
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.16);
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
  .bulk__image-card-content {
    background-color: #f3f4f4;
    padding: 0.5rem 1rem;
  }

  .dialog__padding-horizontal {
    padding-left: 24px;
    padding-right: 24px;
  }

  .dialog__padding-vertical {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@import '../../../../Assets/Styles/colors.scss';

.accordion__container > div > div > div > div > div {
  background-color: #ece9f2;
}

.associated_container {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.16);

  .role__editor {
    height: 100%;
    overflow-y: auto;

    .role__editor_wrapper {
      display: flex;
      width: 100%;

      .role__editor_content {
        width: calc(100% - 280px);

        &.associated__users {
          width: 100%;
        }

        .hr {
          height: 2px;
          width: 100%;
          background-color: #f0f0f0;
        }

        .view__role-list {
          padding: 0.875rem 1rem;
          border-bottom: 1px solid #f0f0f0;
        }

        .rolebox__content-padding {
          padding: 15px 12px; //2.5rem 1.2rem; (as per figma design)
        }

        .rolebox__header-padding {
          padding: 13px 15px;

          .chip__button {
            height: 28px !important;
          }
        }

        .rolebox__icon-margin {
          margin-right: 10px;
        }

        .checkbox__label {
          margin-left: 5px;
        }

        .permissions__box_content-padding {
          padding: 0.2rem 1rem;

          &.content {
            padding: 0.5rem 1rem;
          }

          &.border {
            border-left: 1px solid #f5f4f4;
            border-right: 1px solid #f5f4f4;
          }
        }
      }
    }
  }
}
.max_height_600 {
  max-height: 600px;
}
.box_space {
  padding: 10px !important;
}

.language-error {
  display: flex;
  background-color: #fbebe8;
  padding: 16px 16px 16px;
  margin-top: 1.5rem;
  width: 90%;
  border-radius: 8px;
  color: #d4351c;
  text-align: left;
  font-size: 12px;

  p {
    margin-left: 0.5rem;
    margin-top: 3px;
    font-weight: 600;
  }
}

.section-common-wrapper {
  margin: 25px 0px;
}
.initial-message {
  margin: -12px 0 2rem 0;
}
.mr_10 {
  margin-right: 10px;
}
.w_778 {
  width: 778px;
}
.w_600 {
  width: 600px;
}
.p_50 {
  padding: 50px;
}
.view_icon_style {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: #ece9f2;
}
.view_edit_style {
  width: 28px;
  height: 28px;
  border-radius: 4px;
}
.f_14_w_400 {
  font-size: 14px;
  font-weight: 400;
}
.f_14_w_500 {
  font-size: 14px;
  font-weight: 500;
}

.f_table_head {
  display: flex;
  align-items: center;
  align-content: space-between;
  font-size: 12px;
  font-weight: bold;
}
.list_sticky_header {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #ffff;
}
.margin_top_10 {
  margin-top: 10px;
}
.transcodian_progress_lavel > div > label[data-shrink='false'] {
  position: absolute;
  top: -2px !important;
}

.static-field-container {
  padding-top: 1rem !important;
  .static-fields {
    padding-top: 1rem;
  }
}

.edit-video-button {
  background-color: $primary_purple-light !important;
  color: $primary_purple !important;
  min-width: 28px !important;
  width: 28px !important;
  height: 28px !important;
  padding: 0 !important;
}
.addNewBtnFont {
  font-size: 13px;
  font-weight: 500;
  font-family: inter, sans-serif;
}

.savesearch-popup-wrapper {
  position: absolute;
  top: 55px;
  background-color: #fff;
  color: #3e3f40;
  border-radius: 0px 0px 6px 6px;
  width: 301px;
  height: auto;
  padding: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  .savesearch-popup-header {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
  }

  .search-list {
    max-height: 150px;
    overflow-y: auto;
  }

  .heading-search {
    padding-left: 12px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 1px solid #f7f8f8;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    padding-right: 0px;
    // margin-bottom: 4px;
  }
}

.profile-arrow-icon, .profile-arrow-down-icon {
    width: 1.5rem;
    height: 1.5rem;
}
